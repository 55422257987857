import pdf334 from "../assets/pdfs/4490334.pdf";
import pdf335 from "../assets/pdfs/4490335.pdf";
import pdf336 from "../assets/pdfs/4490336.pdf";
import pdf337 from "../assets/pdfs/4490337.pdf";
import pdf338 from "../assets/pdfs/4490338.pdf";
import pdf339 from "../assets/pdfs/4490339.pdf";

const Docs = [

  {
    id: 4490339,
    File: pdf339,
  },
  {
    id: 4490338,
    File: pdf338,
  },
  {
    id: 4490337,
    File: pdf337,
  },
  {
    id: 4490336,
    File: pdf336,
  },
  {
    id: 4490335,
    File: pdf335,
  },
  {
    id: 4490334,
    File: pdf334,
  }
];

const Data = [
  {
    title: "الرئيسية",
    link: "Home",
  },
  {
    title: "عن المنصة",
    link: "AboutUs",
  },
  {
    title: "أدلة الإستخدام",
    link: "whyUs",
  },
  {
    title: "الأسئلة الشائعة",
    link: "whyUs",
  },
  {
    title: "اتصل بنا",
    link: "whyUs",
  },
];

const Footer_Data = [
  {
    title: "عن المنصة",
  },
  {
    title: "الأسئلة الشائعة",
  },
  {
    title: "أدلة الإستخدام",
  },
  {
    title: "تواصل معنا",
  },
];

export { Data, Footer_Data };

export function getDocs() {
  return Docs;
}

export function getDoc(id) {
  return Docs.find((doc) => doc.id === id);
}
