import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import leftItem from "../assets/images/logo.png";
import rightItem from "../assets/images/jcciNavbarLogo.png";
import stamp from "../assets/images/stamp.png";
import myFont from "../assets/images/NotoKufiArabic.ttf";
import { Data_For_Test } from "./TempraryData";

Font.register({
  family: "Noto Kufi Arabic",
  format: "truetype",
  src: myFont,
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingBottom: 20,
    paddingHorizontal: 15,
    alignItems: "center",
  },

  grayDIV_Top: {
    justifyContent: "space-between",
    margin: 10,
    padding: 10,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F6F7FA",
    height: 100,
    width: "100%",
  },
  grayDIV_Bottow: {
    position: "absolute",
    bottom: 10,
    padding: 10,
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F6F7FA",
    height: 50,
    width: "100%",
  },
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },

  headerBOXar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    fontFamily: "Noto Kufi Arabic",
    textAlign: "left",
    fontSize: 8,
    gap: 4,
  },
  headerBOXen: {
    display: "flex",
    flexDirection: "row",
    fontSize: 8,
    gap: 4,
  },

  leftItem: {
    height: 80,
    width: 80,
  },
  rightItem: {
    height: 25.3,
    width: 82.6,
    marginTop: 15,
  },
});
// Create Document Component
const MyDocument = () => (
  <Document title={"" + Data_For_Test[0].id}>
    <Page wrap="false" size="A4" style={styles.body}>
      {/* Header Place */}
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          height: 100,
          width: "100%",
        }}
      >
        <Image style={styles.leftItem} src={leftItem} />
        <Image style={styles.rightItem} src={rightItem} />
      </View>
      {/* Title Place */}
      <View style={styles.grayDIV_Top}>
        {/* left side */}
        <View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <View style={styles.headerBOXen}>
            <Text>Subscriber ID:</Text>
            <Text>{Data_For_Test[0].SubscriberNumber}</Text>
          </View>
          <View style={styles.headerBOXen}>
            <Text>Subscriber Name:</Text>
            <Text>{Data_For_Test[0].companyNameEN}</Text>
          </View>
          <View style={styles.headerBOXen}>
            <Text>C.R:</Text>
            <Text>{Data_For_Test[0].CR}</Text>
          </View>
          <View style={styles.headerBOXen}>
            <Text>Tel :</Text>
            <Text>{Data_For_Test[0].telephone}</Text>
            <Text>FAX :</Text>
            <Text>0</Text>
          </View>
          <View style={styles.headerBOXen}>
            <Text>Date:</Text>
            <Text>{Data_For_Test[0].opertionDate}</Text>
          </View>
        </View>

        {/* right side */}

        <View style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <View style={styles.headerBOXar}>
            <Text style={styles.textAR}>
              {Data_For_Test[0].SubscriberNumber}
            </Text>
            <Text style={styles.textAR}>:رقم الإشتراك</Text>
          </View>
          <View style={styles.headerBOXar}>
            <Text style={styles.textAR}>{Data_For_Test[0].companyNameAr}</Text>
            <Text style={styles.textAR}>:اسم المشترك</Text>
          </View>
          <View style={styles.headerBOXar}>
            <Text style={styles.textAR}>{Data_For_Test[0].CR}</Text>
            <Text style={styles.textAR}>:السجل التجاري</Text>
          </View>
          <View style={styles.headerBOXar}>
            <Text style={styles.textAR}>0</Text>
            <Text style={styles.textAR}> :فاكس</Text>
            <Text style={styles.textAR}>{Data_For_Test[0].telephone}</Text>
            <Text style={styles.textAR}>:هاتف</Text>
          </View>
          <View style={styles.headerBOXar}>
            <Text style={styles.textAR}>{Data_For_Test[0].opertionDate}</Text>
            <Text style={styles.textAR}>:التاريخ</Text>
          </View>
        </View>
      </View>
      {/* Content & Body Place */}


      {/* 4490339 */}
      <View style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: 8, gap: 5, marginTop: 20, marginLeft: 10, marginRight: 10 }}>

        <Text>Subject: Student Visa</Text>
        <Text>To The Embassy of Turkey</Text>
        <Text>Greetings,</Text>

        <Text style={{ marginTop: 15 }}>This letter certifies that the employee Mr. Bashir Abajabal Abadiga  is employed with us at Saudi Economic and Development Holding Company since 17 Feb 2009, his contract is renewable automatically with mutual consent.</Text>
        <Text style={{ marginTop: 15 }}>Here are the details of the employee:</Text>
        <Text style={{ marginTop: 15 }}>Name: Bashir Abajabal Abadiga</Text>
        <Text>Nationality: Ethiopian</Text>
        <Text>Passport No: EP8924499</Text>
        <Text>Saudi ID (Iqama) No: 2139412858</Text>
        <Text>Employment Start Date: 17 FEB 2009</Text>
        <Text>Actual Profession: Administrative Manager</Text>
        <Text>Monthly Total Salary: SR 17,000 (seventeen thousand) Saudi Riyals only.</Text>
        <Text style={{ marginTop: 15 }}>The above certificate is being issued to Mr. Bashir Abajabal Abadiga for the submittal to Embassy of TURKEY for obtaining a Student visa for his daughter (SEMER BASHIR ABAJABAL). We guarantee his daughter’s return to Saudi Arabia.</Text>
        <Text style={{ marginTop: 15 }}>Thanks and Regards</Text>

        <Text style={{ marginTop: 20 }}>Sincerely</Text>
        <Text style={{ marginTop: 15 }}>Saudi Economic and Development Holding Company.</Text>
        <Text style={{  fontFamily: 'Noto Kufi Arabic' }}>شركة سعودية رائدة في مجال الاستثمارات المسؤولة والمستدامة</Text>
      </View>

      {/* 4490338 */}
      {/* <View style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: 8, gap: 5, marginTop: 20, marginLeft: 10, marginRight: 10 }}>

        <Text>To,</Text>
        <Text>Consulate of USA</Text>
        <Text>Jeddah, K.S.A.</Text>

        <Text style={{ marginTop: 15 }}>Your Excellency,</Text>
        <Text style={{ marginTop: 15 }}>This letter serves to confirm that Mr. MENSUR MEHAMMED ALI Ethiopian nationality Holding of Passport No. EP7337624, Residence Permit no.: 2134251210 with date of birth 01/01/1973. Employee of Ali Saleh Al-Murab Company since December 2021 to the present, in the capacity of a Administrative assistance.</Text>
        <Text style={{ marginTop: 15 }}>His Monthly income is SR15,000/- (Fifteen Thousand Saudi Riyals) with other fringe benefits according to company policy and is on the employment contract of renewable with mutual consent.</Text>
        <Text style={{ marginTop: 20 }}>Thanking for your usual assistance and cooperation.</Text>
        <Text>Very truly yours,</Text>
        <Text style={{ marginTop: 40 }}>_____________________</Text>
        <Text>Waheed Abdullah</Text>
      </View> */}


      {/* 4490337 */}
      {/* <View style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: 8, gap: 5, marginTop: 20, marginLeft: 10, marginRight: 10 }}>

        <Text>To,</Text>
        <Text>U.S Consulate General</Text>
        <Text>Jeddah, K.S.A.</Text>

        <Text style={{ marginTop: 15 }}>Your Excellency,</Text>
        <Text style={{ marginTop: 15 }}>This confirms that Mr. ALI BESHIR ALI, who holds the Saudi residency permit No. 2136406796 and the Ethiopian passport No. EP4088880, would like to let you know that he is employed with us as a Financial Assistant Manager.he makes SAR 10500/- (TEN THOUSAND FIVE HUNDRED Saudi riyals) (SR) a month.</Text>
        <Text style={{ marginTop: 15 }}>This certificate was given upon hes request for the purpose student visa for hes Daughter.We have no objections to her travelling to your country, USA, for study-related purposes. He is in charge of covering all costs associated with her study and stay in USA.Therefore, we would like to ask that the required student visa be granted to her under our complete responsibility and guarantee. Giving her permission to travel to USA, and we promise your Excellency.</Text>
        <Text style={{ marginTop: 15 }}>Please feel free contact us, if you require any further information. Your Sincerely</Text>
        <Text style={{ marginTop: 20 }}>ZAGZOOG HOME APPLIANCES COMPANY</Text>

      </View> */}

      {/* 4490336 */}
      {/* <View style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: 8, gap: 5, marginTop: 20, marginLeft: 10, marginRight: 10 }}>

        <Text>The Honorable Consul General</Text>
        <Text>Canadian Consulate</Text>
        <Text>Jeddah- Kingdom of Saudi Arabia</Text>

        <Text style={{ marginTop: 15 }}>Greetings your Excellency,</Text>
        <Text style={{ marginTop: 15 }}>We hereby confirm that /Mr. JEMIL MOHAMMED AHMED - an Ethiopian Nationality, residing in Jeddah City, Kingdom of Saudi Arabia under Residence Permit No.:2196132860 AND PASSPORT NO. EP71903406 - works with us at FREQUENCY LTD. As a MARKETING SPECIALIST – And receive a salary of (8,500) EIGHT THOUSAND FIVE HUNDRED riyals Per month. - We hereby declare that His Father will bear all travel, accommodation, and return expenses to the Kingdom of Saudi Arabia. And kindly request that you grant him the necessary visa to enter Canada.</Text>
        <Text style={{ marginTop: 15 }}>Thank you, with best regards.</Text>
        <Text style={{ marginTop: 15 }}>Sincerely,</Text>
        <Text style={{ marginTop: 15 }}>General Manager</Text>
        <Text>Nada Gazi Hussien</Text>

      </View> */}

      {/* 4490335 */}
      {/* <View style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: 8, gap: 5, marginTop: 20, marginLeft: 10, marginRight: 10 }}>

        <Text>Consulate General</Text>
        <Text>Canadian Consulate</Text>
        <Text>Jeddah- Kingdom of Saudi Arabia</Text>

        <Text style={{ marginTop: 15 }}>Greetings your Excellency,</Text>
        <Text style={{ marginTop: 15 }}>We hereby confirm that /Mr. MOHAMMED AHMED IBRAHIM - an Ethiopian Nationality, residing in Jeddah City, Kingdom of Saudi Arabia under Residence Permit No.:2146010091 - works with us at Abdul Latif Jameel Motors as an accountant – and receives a salary of (16,600) SIXTEEN THOUSAND SIX HUNDRED RIYALS Per month. Since his son/Mr. JEMIL MOHAMMED AHMED - an Ethiopian Nationality, who holds Passport No. EP71903406 wishes to travel to Canada for visiting purposes</Text>
        <Text style={{ marginTop: 15 }}>We hereby declare that His Father will bear all travel, accommodation and return expenses to the Kingdom of Saudi Arabia, and I kindly request that you grant him the necessary visa to enter Canada.</Text>
        <Text style={{ marginTop: 15 }}>Thank you, with best regards</Text>

      </View> */}

      {/* 4490334 */}
      {/* <View style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', fontSize: 8, gap: 5, marginTop: 20, marginLeft: 10, marginRight: 10 }}>

        <Text>Subject: Tourist Visa</Text>
        <Text>To The Embassy of Turkey</Text>
        <Text>Greetings,</Text>

        <Text style={{ marginTop: 15 }}>This letter certifies that the employee ABDULAZIZ is employed with us at JEDDAH, SAUDI Arabia since 29 Jan 2022 and he is on top of the job and his contract is renewable automatically with mutual consent.</Text>
        <Text style={{ marginTop: 15 }}>Here are the details of the employee:</Text>
        <Text>Name: ABDULAZIZ MOHAMMED RAGEH HESANI</Text>
        <Text>Passport No: 09360128</Text>
        <Text>Nationality: YEMEN</Text>
        <Text>Saudi ID (Iqama) No: 2503826840</Text>
        <Text>Employment Start Date: 29 Jan 2022</Text>
        <Text>Actual Profession: SALES MANAGEMENT </Text>
        <Text>Monthly Total Salary: 8350 SR </Text>
        <Text style={{ marginTop: 15 }}>The above certificate is being issued to Mr. ABDULAZIZ HESANI for the submittal to Embassy of TURKEY for obtaining a Tourist visa for him. We guarantee his return to Saudi Arabia immediately after the visit to resume his job with us.</Text>
        <Text style={{ marginTop: 15 }}>Thanks and Regards</Text>
        <Text style={{ marginTop: 15 }}>Sincerely</Text>
        <Text style={{ marginTop: 15 }}>GOMAEAN MANZIL RASHIYDY CORPORAITION.</Text>

      </View> */}

      {/* Stamp Place  */}
      <View
        style={{
          alignItems: "baseline",
          position: "absolute",
          bottom: 65,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          borderWidth: 1.5,
          borderColor: "#F6F7FA",
          borderRadius: 4,
          height: 80,
          width: "100%",
          padding: 4,
        }}
      >
        <View style={{ display: "flex", flexDirection: "column" }}>
          <View style={styles.headerBOXar}>
            <Text style={styles.textAR}>{Data_For_Test[0].autorizedPIN}</Text>
            <Text style={styles.textAR}>:رمز المفوض</Text>
          </View>
          <View style={styles.headerBOXar}>
            <Text style={styles.textAR}>
              {Data_For_Test[0].applicantNameAR}
            </Text>
            <Text style={styles.textAR}>:الإسم</Text>
          </View>
        </View>

        <View
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: 25,
            }}
          >
            <View style={styles.headerBOXar}>
              <Text style={styles.textAR}>
                {Data_For_Test[0].companyNameAr}
              </Text>
              <Text style={styles.textAR}>:اسم الجهة</Text>
            </View>
            <View style={styles.headerBOXar}>
              <Text style={styles.textAR}>
                {Data_For_Test[0].operationNumber}
              </Text>
              <Text style={styles.textAR}>:رقم العملية</Text>
            </View>
            <View style={styles.headerBOXar}>
              <Text style={styles.textAR}>
                {Data_For_Test[0].SubscriberNumber}
              </Text>
              <Text style={styles.textAR}>:رقم الإشتراك</Text>
            </View>
          </View>
          <Image
            style={{ height: 60, width: 60, marginRight: 10 }}
            src={Data_For_Test[0].qrCodeImage}
          />
          <Image style={{ height: 70 }} src={stamp} />
        </View>
      </View>
      Footer Place
      <View style={styles.grayDIV_Bottow}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
            width: "100%",
            fontFamily: "Noto Kufi Arabic",
            fontSize: 9,
          }}
        >
          <Text>
            هذه الوثيقة قد تم إصدارها دون أدنى مسؤولية على الغرفة من خلال نظام
            الخدمات الإلكترونية لاتحاد الغرف السعودية و أي إضافة أو كشط أو
          </Text>
          <Text>
            https://user.saudichambers.sa/Home تعديل على الوثيقة تعتبر لاغية
            وللتحقق من محتواها يرجى زيارة الموق الالكتروني
          </Text>
          {/* <Text style={styles.headerBOXar}></Text> */}
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
