import client334 from "../assets/qrs/4490334.png";
import client335 from "../assets/qrs/4490335.png";
import client336 from "../assets/qrs/4490336.png";
import client337 from "../assets/qrs/4490337.png";
import client338 from "../assets/qrs/4490338.png";
import client339 from "../assets/qrs/4490339.png";

const Data_For_Test = [

  {
    id: 4490339,
    applicantNameAR: "وحيد عبدالله",
    applicantNameEN: "Waheed Abdullah",
    companyNameAr: "شركة سعودية رائدة في مجال الاستثمارات",
    companyNameEN: "Saudi Economic and Development",
    telephone: "0126523444",
    CR: "4030125150",
    SubscriberNumber: "536129",
    opertionDate: "2/10/2024",
    operationNumber: "3012297",
    autorizedPIN: "1048490125",
    qrCodeImage: client339,
  },
  {
    id: 4490338,
    applicantNameAR: "وحيد عبدالله",
    applicantNameEN: "Waheed Abdullah",
    companyNameAr: "مؤسسة علي صالح المربعي",
    companyNameEN: "Ali Saleh Al-Murabi Est.",
    telephone: "0126523444",
    CR: "4030125150",
    SubscriberNumber: "536129",
    opertionDate: "19/09/2024",
    operationNumber: "3012297",
    autorizedPIN: "1048490125",
    qrCodeImage: client338,
  },
  {
    id: 4490337,
    applicantNameAR: "سارة الغامدي",
    applicantNameEN: "Sara AlGhamdi",
    companyNameAr: "شركة الزقزوق للأجهزة المنزلية",
    companyNameEN: "ZAGZOOG HOME APPLIANCES COMPANY",
    telephone: "0126523444",
    CR: "4030984403",
    SubscriberNumber: "591251",
    opertionDate: "29/09/2024",
    operationNumber: "3012175",
    autorizedPIN: "1010303532",
    qrCodeImage: client337,
  },
  {
    id: 4490336,
    applicantNameAR: "بيتر أبيرلي",
    applicantNameEN: "Peter Aberle",
    companyNameAr: "التردد المحدودة",
    companyNameEN: "FREQUENCY LTD",
    telephone: "0126592861",
    CR: "4030018622",
    SubscriberNumber: "829311",
    opertionDate: "17/09/2024",
    operationNumber: "3012099",
    autorizedPIN: "1010501974",
    qrCodeImage: client336,
  },
  {
    id: 4490335,
    applicantNameAR: "ابراهيم خالد الزهراني",
    applicantNameEN: "Ibrahim Khalid Zahrani",
    companyNameAr: "شركة عبد اللطيف جميل",
    companyNameEN: "Abdul Latif Jameel Motors",
    telephone: "0126523444",
    CR: "4030984403",
    SubscriberNumber: "591251",
    opertionDate: "17/09/2024",
    operationNumber: "3012175",
    autorizedPIN: "1010303532",
    qrCodeImage: client335,
  },
  {
    id: 4490334,
    applicantNameAR: "ابراهيم خالد الزهراني",
    applicantNameEN: "Ibrahim Khalid Zahrani",
    companyNameAr: "شركة جوماين مانزيل رشيدي",
    companyNameEN: "GOMAEAN MANZIL RASHIYDY CORPORAITION.",
    telephone: "0126523444",
    CR: "4030984403",
    SubscriberNumber: "591251",
    opertionDate: "10/09/2024",
    operationNumber: "3012175",
    autorizedPIN: "1010303532",
    qrCodeImage: client334,
  },

];

export { Data_For_Test };
